<template>
    <div class="container">
        <div
            @click="goback"
            style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px"
        >
            <i class="el-icon-back"></i> 返回
        </div>
        <div>
            <el-space
                direction="vertical"
                alignment="start"
                :size="30"
                :fill="fill"
                wrap
            >
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h1>用户信息</h1>
                        </div>
                    </template>

                    <el-form
                        ref="userform"
                        :model="userform"
                        style="width:100%"
                        :inline="true"
                        label-width="140px"
                    >
                        <el-form-item label="用户唯一编码" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.uid"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="昵称" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.nickname"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="注册国家" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.register_country"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="注册IP" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.register_ip"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="注册时间" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.register_time"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="账号类型" label-width="160px">
                            <el-input
                                disabled
                                v-model="accountType[userform.account_type]"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="头像地址" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.avatar"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="应用标志" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.schema"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="涂鸦区域" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.tuya_region"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="涂鸦uid" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.tuya_uid"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="更新时间" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.update_time"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="账号删除时间" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.delete_time"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="账号" label-width="160px">
                            <el-input
                                disabled
                                v-model="userform.account"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </el-card>

                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h1>登录列表</h1>
                        </div>
                    </template>
                    <el-table border v-bind:data="loginlist">
                        <el-table-column label="app的包名">
                            <template v-slot="scope">
                                <span>{{ scope.row.app_package_name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="APP版本">
                            <template v-slot="scope">
                                <span>{{ scope.row.app_version }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="国家码">
                            <template v-slot="scope">
                                <span>{{ scope.row.country_code }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的手机品牌">
                            <template v-slot="scope">
                                <span>{{ scope.row.device_brand }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的唯一标志">
                            <template v-slot="scope">
                                <span>{{ scope.row.device_code }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="国家语言">
                            <template v-slot="scope">
                                <span>{{ scope.row.device_language }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的手机型号">
                            <template v-slot="scope">
                                <span>{{ scope.row.device_model }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的系统版本">
                            <template v-slot="scope">
                                <span>{{
                                    scope.row.device_system_version
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备类型">
                            <template v-slot="scope">
                                <span>{{
                                    scope.row.device_type == 1
                                        ? '安卓'
                                        : scope.row.device_type == 2
                                        ? 'IOS'
                                        : '未知'
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的时区">
                            <template v-slot="scope">
                                <span>{{ scope.row.device_zone }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="access_token过期的UTC时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.expires_in)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="应用唯一编码">
                            <template v-slot="scope">
                                <span>{{ scope.row.schema }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="最近登陆时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.login_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的手机屏幕">
                            <template v-slot="scope">
                                <span>{{ scope.row.phone_screen }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的推送token">
                            <template v-slot="scope">
                                <el-button
                                    size="mini"
                                    @click="Looktoken(scope.row.push_token)"
                                    >查看</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="设备的推送类型">
                            <template v-slot="scope">
                                <span>{{
                                    changePushType(scope.row.push_type)
                                }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h1>信任设备列表</h1>
                        </div>
                    </template>
                    <el-table border v-bind:data="trustDevlist">
                        <el-table-column label="用户唯一编码">
                            <template v-slot="scope">
                                <span>{{ scope.row.uid }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="登录设备唯一编码">
                            <template v-slot="scope">
                                <span>{{ scope.row.device_code }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备名">
                            <template v-slot="scope">
                                <span>{{ scope.row.device_name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="添加时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.create_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="手机品牌">
                            <template v-slot="scope">
                                <span>{{ scope.row.phone_brand }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="手机型号">
                            <template v-slot="scope">
                                <span>{{ scope.row.phone_model }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h1>用户绑定的涂鸦设备</h1>
                        </div>
                    </template>
                    <el-table border v-bind:data="tuyalist">
                        <el-table-column label="设备id">
                            <template v-slot="scope">
                                <span>{{ scope.row.id }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备uuid">
                            <template v-slot="scope">
                                <span>{{ scope.row.uuid }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备名称">
                            <template v-slot="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="product_id">
                            <template v-slot="scope">
                                <span>{{ scope.row.product_id }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属产品型号">
                            <template v-slot="scope">
                                <span>{{ scope.row.model }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="serve_ip">
                            <template v-slot="scope">
                                <span>{{ scope.row.ip }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="在线状态">
                            <template v-slot="scope">
                                <span
                                    v-if="scope.row.online == 1"
                                    style="color:green"
                                    >在线</span
                                >
                                <span v-else style="color:red">不在线</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备上次配网时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.active_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150px">
                            <template v-slot="scope">
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="TuyaInfo(scope.row)"
                                    >详情</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="TuyaLog(scope.row)"
                                    >日志</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h1>用户与app关系列表</h1>
                        </div>
                    </template>
                    <el-table border v-bind:data="list">
                        <el-table-column label="活动推送">
                            <template v-slot="scope">
                                <el-button
                                    v-if="scope.row.active_push == 1"
                                    type="success"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'active_push',
                                            scope.row,
                                            true
                                        )
                                    "
                                    >接收</el-button
                                >
                                <el-button
                                    v-else
                                    type="danger"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'active_push',
                                            scope.row,
                                            false
                                        )
                                    "
                                    >不接收</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="双重认证">
                            <template v-slot="scope">
                                <el-button
                                    v-if="scope.row.auth_tow == 1"
                                    type="success"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'auth_tow',
                                            scope.row,
                                            true
                                        )
                                    "
                                    >开启</el-button
                                >
                                <el-button
                                    disabled
                                    v-else
                                    type="danger"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'auth_tow',
                                            scope.row,
                                            false
                                        )
                                    "
                                    >不开启</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="debug用户">
                            <template v-slot="scope">
                                <el-button
                                    v-if="scope.row.is_debug == 1"
                                    type="success"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'is_debug',
                                            scope.row,
                                            true
                                        )
                                    "
                                    >是</el-button
                                >
                                <el-button
                                    v-else
                                    type="danger"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'is_debug',
                                            scope.row,
                                            false
                                        )
                                    "
                                    >不是</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="开发者">
                            <template v-slot="scope">
                                <el-button
                                    v-if="scope.row.is_developer == 1"
                                    type="success"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'is_developer',
                                            scope.row,
                                            true
                                        )
                                    "
                                    >是</el-button
                                >
                                <el-button
                                    v-else
                                    type="danger"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'is_developer',
                                            scope.row,
                                            false
                                        )
                                    "
                                    >不是</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="测试用户">
                            <template v-slot="scope">
                                <el-button
                                    v-if="scope.row.is_test == 1"
                                    type="success"
                                    size="mini"
                                    @click="
                                        changeStatus('is_test', scope.row, true)
                                    "
                                    >是</el-button
                                >
                                <el-button
                                    v-else
                                    type="danger"
                                    size="mini"
                                    @click="
                                        changeStatus(
                                            'is_test',
                                            scope.row,
                                            false
                                        )
                                    "
                                    >不是</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="首次登陆时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.login_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="应用唯一编码">
                            <template v-slot="scope">
                                <span>{{ scope.row.schema }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150px">
                            <template v-slot="scope">
                                <el-button
                                    v-if="scope.row.is_debug == 1"
                                    size="mini"
                                    type="info"
                                    @click="AppLog(scope.row)"
                                    >App日志</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h1>用户绑定设备列表</h1>
                        </div>
                    </template>
                    <el-table border v-bind:data="bindlist">
                        <!-- <el-table-column label="设备编号">
					  <template v-slot="scope">
					    <span>{{ scope.row.device_id }}</span>
					  </template>
					</el-table-column> -->
                        <el-table-column label="设备uuid">
                            <template v-slot="scope">
                                <span>{{ scope.row.uuid }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备名称">
                            <template v-slot="scope">
                                <span>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否绑定">
                            <template v-slot="scope">
                                <span
                                    style="color:green"
                                    v-if="scope.row.status == 1"
                                    >{{ '已绑定' }}</span
                                >
                                <span
                                    style="color:red"
                                    v-else-if="scope.row.status == 2"
                                    >{{ '已解绑' }}</span
                                >
                                <span v-else>{{ '未知' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="绑定关系">
                            <template v-slot="scope">
                                <span>{{
                                    scope.row.bind_type == 1
                                        ? '主人'
                                        : scope.row.bind_type == 2
                                        ? '分享'
                                        : '未知'
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="内部型号">
                            <template v-slot="scope">
                                <span>{{ scope.row.model }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备关系排序">
                            <template v-slot="scope">
                                <span>{{ scope.row.sort }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="休眠/p2p在线状态">
                            <template v-slot="scope">
                                <span
                                    style="color:green"
                                    v-if="scope.row.open_status == 1"
                                    >在线/</span
                                >
                                <span style="color:red" v-else>不在线/</span>
                                <span
                                    style="color:green"
                                    v-if="scope.row.p2p_online == 1"
                                    >在线</span
                                >
                                <span style="color:red" v-else>不在线</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="绑定时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.bind_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="解绑时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.delete_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="第一次绑定时间">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.first_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="350px">
                            <template v-slot="scope">
                                <router-link
                                    :to="
                                        '/iot/onlineinfo/' +
                                            scope.row.uuid +
                                            '@' +
                                            params
                                    "
                                    style="margin-right:10px"
                                >
                                    <el-button type="info" size="mini"
                                        >详情</el-button
                                    >
                                </router-link>
                                <el-button
                                    type="danger"
                                    v-if="scope.row.status == 1"
                                    size="mini"
                                    @click="unbinding(scope.row)"
                                    >取消绑定</el-button
                                >
                                <el-button
                                    v-if="scope.row.status == 1"
                                    type="info"
                                    size="mini"
                                    @click="CreateCloud(scope.row)"
                                    >开通套餐</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="GetCloudInfo(scope.row)"
                                    >云存储</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-card class="box-card">
                    <template #header>
                        <div class="card-header">
                            <h1>（亚马逊）订单列表</h1>
                        </div>
                    </template>
                    <el-table border v-bind:data="warrantyOrderList">
                        <el-table-column label="渠道" width="80px">
                            <template v-slot="scope">
                                <span>{{
                                    scope.row.channel === 1 ? '亚马逊' : '其他'
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="订单号" width="180px">
                            <template v-slot="scope">
                                <span>{{ scope.row.order_no }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="订单联系邮箱" width="190px">
                            <template v-slot="scope">
                                <span>{{ scope.row.email }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="设备型号">
                            <template v-slot="scope">
                                <span>{{ scope.row.model_code }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="uuid">
                            <template v-slot="scope">
                                <span>{{ scope.row.uuid }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" width="180px">
                            <template v-slot="scope">
                                <span>{{
                                    changeTime(scope.row.create_time)
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="备注" show-overflow-tooltip>
                            <template v-slot="scope">
                                <span>{{ scope.row.remark }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120px">
                            <template v-slot="scope">
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="openAmazonRemark(scope.row)"
                                    >备注</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog
                        v-model="amazonRemarkDialog"
                        :modal="true"
                        :append-to-body="true"
                        title="修改备注"
                        width="30%"
                    >
                        <el-form class="modify-uuid">
                            <el-form-item label="" prop="pass">
                                <el-input
                                    v-model="amazonRemark"
                                    :rows="4"
                                    type="textarea"
                                    placeholder=""
                                />
                            </el-form-item>
                        </el-form>
                        <template #footer>
                            <span class="dialog-footer">
                                <el-button @click="amazonRemarkDialog = false"
                                    >取消</el-button
                                >
                                <el-button
                                    type="primary"
                                    @click="updateAmazonRemark()"
                                    >确定</el-button
                                >
                            </span>
                        </template>
                    </el-dialog>
                </el-card>
            </el-space>
        </div>

        <el-dialog
            title="开通云存储情况"
            v-model="cloudFormVisible"
            width="1400px"
            center
        >
            <el-table border v-bind:data="cloudList">
                <el-table-column label="订单id">
                    <template v-slot="scope">
                        <span>{{ scope.row.order_id }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="设备uuid">
                    <template v-slot="scope">
                        <span>{{ scope.row.uuid }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐开始时间">
                    <template v-slot="scope">
                        <span>{{ changeTime(scope.row.start_time) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐结束时间">
                    <template v-slot="scope">
                        <span>{{ changeTime(scope.row.end_time) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="套餐内云存储时间(天)">
                    <template v-slot="scope">
                        <span>{{ scope.row.file_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template v-slot="scope">
                        <span>{{
                            scope.row.status == 0
                                ? '未订阅'
                                : scope.row.status == 1
                                ? '已订阅'
                                : '未知'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="云存储事件类型">
                    <template v-slot="scope">
                        <span>{{
                            scope.row.is_event == 0
                                ? '全时'
                                : scope.row.is_event == 1
                                ? '事件'
                                : '未知'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订阅的app">
                    <template v-slot="scope">
                        <span>{{ scope.row.schema }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="350px">
                    <template v-slot="scope">
                        <el-button
                            v-if="scope.row.file_time !== 0"
                            type="text"
                            @click="S3KeyAnalyse(scope.row)"
                            >S3key分析</el-button
                        >
                        <el-button
                            v-if="scope.row.status != 0"
                            type="info"
                            @click="cancelCloudRenew(scope.row)"
                            >取消续订</el-button
                        >
                        <el-button
                            v-else
                            type="info"
                            @click="closeCloud(scope.row)"
                            >关闭云存储</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cloudFormVisible = false"
                        >取消</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <el-dialog
            title="关闭云存储"
            v-model="isShowCloseCloudDialog"
            width="300px"
            center
        >
            <div class="close_cloud">
                <select v-model="isReturnMoney" class="close_cloud_select">
                    <option :value="1">退款</option>
                    <option :value="2">不退款</option>
                </select>
                <div class="dialog-footer">
                    <el-button @click="closelCloudConfirm()">确定</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- s3key分析 -->
        <el-dialog
            title="S3key分析"
            v-model="s3FormVisible"
            width="1000px"
            center
        >
            <el-form
                :model="s3form"
                :inline="true"
                class="demo-form-inline"
                :rules="s3formrules"
                ref="logform"
            >
                <el-form-item label="日期" prop="date" label-width="60px">
                    <el-date-picker
                        v-model="s3form.date"
                        type="date"
                        placeholder="Pick a Date"
                        @change="GetS3key"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form
                v-if="formVisibl"
                :model="analyseform"
                :inline="true"
                class="demo-form-inline"
            >
                <el-form-item label="文件位置:" label-width="100px">
                    <el-input
                        disabled
                        style="width:200px"
                        v-model="analyseform.file_brand"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="版本:" label-width="100px">
                    <el-input
                        disabled
                        style="width:200px"
                        v-model="analyseform.version"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="区域:" label-width="100px">
                    <el-input
                        disabled
                        style="width:200px"
                        v-model="analyseform.region"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="文件目录:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="analyseform.file_key"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="品牌分析:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="analyseform.brand_equal"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="目录分析:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="analyseform.key_appid_right"
                        placeholder=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="位置分析:" label-width="100px">
                    <el-input
                        disabled
                        style="width:620px"
                        v-model="analyseform.key_brand_right"
                        placeholder=""
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-table border v-bind:data="s3KeyList">
                <el-table-column label="key">
                    <template v-slot="scope">
                        <span>{{ scope.row.key }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog
            title="开通套餐"
            v-model="packFormVisible"
            width="30%"
            center
        >
            <el-form
                label-width="80px"
                ref="creatcloudform"
                :model="creatcloudform"
                :rules="rules"
            >
                <el-form-item label="套餐" prop="product_id">
                    <el-select v-model="creatcloudform.product_id">
                        <el-option
                            v-for="item in packList"
                            :key="item.SetMeal.code"
                            :label="item.SetMeal.name"
                            v-show="item.SetMeal.status !== 3"
                            :value="item.SetMeal.code"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="套餐时长" prop="cycle">
                    <el-input-number
                        v-model="creatcloudform.cycle"
                        :min="0"
                    ></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="packFormVisible = false">取消</el-button>
                    <el-button @click="Validate(CreateConfirm)">确认</el-button>
                </span>
            </template>
        </el-dialog>

        <!----------------------------分割线------------------------------------- -->
        <el-dialog
            title="设备的推送token"
            v-model="tokenFormVisible"
            width="600px"
        >
            <el-form :model="tokenform" ref="tokenform">
                <el-form-item label="设备的推送token" label-width="120px">
                    <el-input
                        type="textarea"
                        disabled
                        :rows="3"
                        v-model="tokenform.token"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="tokenFormVisible = false"
                        >关闭</el-button
                    >
                </span>
            </template>
        </el-dialog>

        <el-dialog title="App日志" v-model="logFormVisible" width="1000px">
            <el-form
                :model="logform"
                :inline="true"
                class="demo-form-inline"
                :rules="logformrules"
                ref="logform"
            >
                <!-- <el-form-item label="品牌" prop="brand" label-width="60px">
          <el-select v-model="logform.brand" clearable>
            <el-option
              v-for="item in brandlist"
              :key="item.name"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item> -->
                <el-form-item label="日期" prop="date" label-width="60px">
                    <el-date-picker
                        v-model="logform.date"
                        type="date"
                        placeholder="Pick a Date"
                        @change="GetLog"
                    >
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="" prop="" label-width="60px">
          <el-button type="primary" size="mini" @click="LogValidate(GetLog)">查询</el-button>
        </el-form-item> -->
            </el-form>
            <el-table border v-bind:data="logList">
                <el-table-column label="key">
                    <template v-slot="scope">
                        <span>{{ scope.row.key }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150px">
                    <template v-slot="scope">
                        <el-button
                            type="info"
                            size="mini"
                            @click="DownLog(scope.row)"
                            >下载</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-button
                    type="text"
                    :disabled="logList.length < logform.rows"
                    @click="NextPageLog"
                    size="mini"
                    >下一页</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
            title="调整用户状态"
            v-model="statusFormVisible"
            width="600px"
        >
            <el-form :model="statusform" :rules="statusrules" ref="statusform">
                <el-form-item
                    label="配置类型"
                    prop="change_type"
                    label-width="120px"
                >
                    <el-select v-model="statusform.change_type">
                        <el-option
                            label="关闭双重认证"
                            value="FactorAuth"
                        ></el-option>
                        <el-option
                            label="配置debug用户"
                            value="Debug"
                        ></el-option>
                        <el-option
                            label="配置测试用户"
                            value="Test"
                        ></el-option>
                        <el-option
                            label="配置开发者"
                            value="Developer"
                        ></el-option>
                        <el-option
                            label="推送配置"
                            value="ActivePush"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="配置值"
                    v-if="statusform.change_type !== 'FactorAuth'"
                    prop="value"
                    label-width="120px"
                >
                    <el-select
                        v-model="statusform.value"
                        :disabled="statusform.change_type == 'FactorAuth'"
                    >
                        <el-option
                            v-if="statusform.change_type == 'Test'"
                            label="普通用户"
                            :value="0"
                        ></el-option>
                        <el-option
                            v-if="statusform.change_type == 'Test'"
                            label="测试用户"
                            :value="1"
                        ></el-option>
                        <el-option
                            v-if="statusform.change_type == 'Debug'"
                            label="普通用户"
                            :value="0"
                        ></el-option>
                        <el-option
                            v-if="statusform.change_type == 'Debug'"
                            label="debug用户"
                            :value="1"
                        ></el-option>
                        <el-option
                            v-if="statusform.change_type == 'Developer'"
                            label="普通账号"
                            :value="0"
                        ></el-option>
                        <el-option
                            v-if="statusform.change_type == 'Developer'"
                            label="开发者账号"
                            :value="1"
                        ></el-option>
                        <el-option
                            v-if="statusform.change_type == 'ActivePush'"
                            label="拒绝推送"
                            :value="0"
                        ></el-option>
                        <el-option
                            v-if="statusform.change_type == 'ActivePush'"
                            label="接受推送"
                            :value="1"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="statusFormVisible = false"
                        >取 消</el-button
                    >
                    <el-button
                        type="primary"
                        @click="statusValidate(statusconfirm)"
                        >提交</el-button
                    >
                </span>
            </template>
        </el-dialog>

        <!-- 涂鸦详情 -->
        <el-dialog title="涂鸦设备详情" v-model="tuyaFormVisible" width="800px">
            <el-form :model="tuyaform" inline>
                <el-form-item label="产品型号" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.model"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="产品id" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.product_id"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备名称" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所属用户" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.tuyauid"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备UUID" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.uuid"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备IP" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.ip"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="在线状态" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.online"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="初次配网时间" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.create_time"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="上次配网时间" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.active_time"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="状态更新时间" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.update_time"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所在时区" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.time_zone"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="产品类别" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.category"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="密钥" label-width="120px">
                    <el-input
                        disabled
                        v-model="tuyaform.local_key"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="tuyaFormVisible = false"
                        >取 消</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <!-- 涂鸦用户详情信息 -->
        <el-dialog v-model="userInfoVisible" title="用户详情" width="400px">
            <p style="padding: 8px 0px">
                <b>用户账号：</b><span>{{ userInfo?.account }}</span>
            </p>
            <p style="padding: 8px 0px">
                <b>区域：</b><span>{{ userInfo?.region }}</span>
            </p>
            <p style="padding: 8px 0px">
                <b>终端：</b><span>{{ userInfo?.schema }}</span>
            </p>
            <p style="padding: 8px 0px">
                <b>涂鸦uid：</b><span>{{ userInfo?.tuya_uid }}</span>
            </p>
            <p style="padding: 8px 0px">
                <b>用户标识：</b><span>{{ userInfo?.uid }}</span>
            </p>
        </el-dialog>
        <!-- 涂鸦日志 -->
        <el-dialog title="涂鸦设备日志" v-model="tuyaLogVisible" width="1000px">
            <el-form
                :model="tuyalogform"
                :inline="true"
                class="demo-form-inline"
                :rules="tuyalogrules"
                ref="tuyalogform"
            >
                <el-form-item>
                    <el-select
                        v-model="tuyalogform.type"
                        @change="GetTuyaLogList"
                        placeholder="日志查询支持的类型"
                    >
                        <el-option label="上线" :value="1"></el-option>
                        <el-option label="下线" :value="2"></el-option>
                        <el-option label="设备激活" :value="3"></el-option>
                        <el-option label="设备重置" :value="4"></el-option>
                        <el-option label="指令下发" :value="5"></el-option>
                        <el-option label="固件升级" :value="6"></el-option>
                        <el-option label="数据点上报" :value="7"></el-option>
                        <el-option label="设备信号量" :value="8"></el-option>
                        <el-option label="设备重启" :value="9"></el-option>
                        <el-option label="定时信息" :value="10"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="tuyalogform.codes"
                        @change="GetTuyaLogList"
                        placeholder="设备支持的功能点"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="tuyalogform.start_row_key"
                        @change="GetTuyaLogList"
                        placeholder="查询Hbase的行键"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="datevalue"
                        @change="GetTuyaLogList"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="起始时间"
                        end-placeholder="结束时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-input-number
                        v-model="tuyalogform.size"
                        :min="1"
                        @change="GetTuyaLogList"
                        placeholder="查询的行数"
                    />
                </el-form-item>
            </el-form>
            <el-table border v-bind:data="tuyalogList">
                <el-table-column label="事件的类型">
                    <template v-slot="scope">
                        <span>{{ ChangeStatus(scope.row.event_id) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template v-slot="scope">
                        <span>{{
                            scope.row.status == 1 ? '有效' : '未知'
                        }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="tuyaLogVisible = false">取 消</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
import api from '../../../axios/pack'
import {
	logEventStatus
} from '../../../assets/js/dictionary'
export default {
	name: '',
	mixins: [],
	components: {

	},
	props: {},
	data() {
		return {
			userInfoVisible: false,
			userInfo: {},
			pushType: [{
					value: '极光',
					key: 1
				},
				{
					value: 'FCM',
					key: 2
				},
				{
					value: 'APNS',
					key: 3
				},
				{
					value: '个推',
					key: 4
				},
				{
					value: '友盟',
					key: 5
				},
			],
			accountType: {
				0: '邮箱',
			},
			uid: '',
			region: '',
			schema: '',
			form: {
				account: '',
				country: '',
				create_time: '',
				nickname: '',
				region: '',
				schema: '',
				tuya_uid: '',
				uid: '',
				zone: '',
			},
			userform: {
				account: '',
				account_type: '',
				avatar: '',
				delete_time: '',
				nickname: '',
				register_country: '',
				register_ip: '',
				register_time: '',
				schema: '',
				tuya_region: '',
				uid: '',
				update_time: '',
			},
			loginlist: [],
			trustDevlist: [],
			list: [],
			search: {
				page: 1,
				limit: 10,
			},
			total: 0,
			predisabled: false,
			nextdisabled: false,
			statusFormVisible: false,
			statusform: {
				change_type: '',
				uid: '',
				value: '',
				schema: '',
			},
			statusrules: {
				change_type: [{
					required: true,
					message: '请选择配置类型',
					trigger: 'blur'
				}],
				value: [{
					required: true,
					message: '请选择配置值',
					trigger: 'blur'
				}],
			},
			tokenFormVisible: false,
			tokenform: {
				token: ''
			},
			tuyalist: [],
			logform: {
				date: '',
				brand: '',
				appid: '',
				lastKey: '',
				rows: 10,
				prefix: ''
			},
			logFormVisible: false,
			isShowCloseCloudDialog: false,
			closeCloudId: '',
			// closeCloudUid:'',
			// closeCloudUuid:'',
			isReturnMoney: 2,
			logformrules: {
				date: [{
					required: true,
					message: '请选择日期',
					trigger: 'blur'
				}]
			},
			brandlist: [],
			logList: [],
			tuyaFormVisible: false,
			tuyaform: {},
			// 涂鸦日志
			datevalue: '',
			tuyaLogVisible: false,
			tuyalogform: {
				device_id: '',
				tuya_region: '',
				type: 1,
				codes: '',
				start_row_key: '',
				start_time: '',
				end_time: '',
				size: 10,
			},
			tuyalogrules: {},
			tuyalogList: [],
			// 绑定设备
			bindlist: [],
			packFormVisible: false,
			creatcloudform: {
				product_id: '',
				uid: '',
				uuid: '',
				region: '',
				cycle: ''
			},
			rules: {
				product_id: [{
					required: true,
					message: '请选择套餐',
					trigger: 'blur'
				}],
				cycle: [{
					required: true,
					message: '请输入套餐时长',
					trigger: 'blur'
				}],
			},
			packList: [],
			cloudFormVisible: false,
			cloudList: [{}],
			currencyList: [],
			// s3key分析
			formVisibl: false,
			analyseform: {
				appid_equal: '',
				file_brand: '',
				brand_equal: '',
				version: '',
				region: '',
				file_key: '',
				key_appid_right: '',
				key_brand_right: '',
			},
			s3FormVisible: false,
			s3form: {
				date: '',
				appid: '3dab98eee85b7ae8',
				uid: '',
				uuid: '',
				file_time: ''
			},
			s3formrules: {},
			s3KeyList: [],
            warrantyOrderList: [],
            amazonCrrrentRow: {},
            amazonRemark: '',
            amazonRemarkDialog: false
		}
	},
	computed: {},
	watch: {},
	methods: {
        GetWarrantyOrderList() {
            this.api.WarrantyOrderList({
                page: 1,
                limit: 1000,
                email: '',
                uid: this.uid,
                order_no: '',
                start_time: '',
                end_time: ''
            }).then(res => {
                if (res.data.code == 200) {
                this.warrantyOrderList = res.data.data.list
                this.total = res.data.data.page_info.total
                return
            }
            this.$message.error('获取失败' + res.data.msg)
        })
        },
        openAmazonRemark(row) {
            this.amazonCrrrentRow = row
            this.amazonRemark = row.remark
            this.amazonRemarkDialog = true
        },
        updateAmazonRemark() {
            this.api.WarrantyOrderUpdate({
                id: this.amazonCrrrentRow.id,
                remark: this.amazonRemark
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('修改成功')
                    this.amazonRemarkDialog = false
                    this.GetWarrantyOrderList()
                    return
                }
                this.$message.error('调用失败:' + res.data.msg)
            })
        },
		// 绑定设备
		GetBindDevice() {
			this.api.UserDeviceList({
				page: 1,
				limit: 10,
				uid: this.uid,
				region: this.region,
			}).then(res => {
				if (res.data.code == 200) {
					this.bindlist = res.data.data
                    this.GetWarrantyOrderList()
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		unbinding(item) {
			this.api.DeleteBind({
				schema: this.schema,
				uid: item.uid,
				uuid: item.uuid,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('解绑成功')
					return
				}
				this.$message.error('解绑失败' + res.data.msg)
			})
		},
		CreateCloud(item) {
			this.packFormVisible = true
			this.creatcloudform.uid = item.uid
			this.creatcloudform.uuid = item.uuid
			this.creatcloudform.region = this.search.region
			api.PackageList({
				page: 1,
				limit: 100,
				kname: '',
				code: '',
				is_event: -1,
				// product_model_code: this.form.device.model_code,
			}).then(res => {
				if (res.data.code == 200) {
					this.packList = res.data.data.data
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		GetCloudInfo(item) {
			this.s3form.uid = item.uid
			this.api.OnlineCloudInfo({
				uid: item.uid,
				uuid: item.uuid,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.cloudList = res.data.data
					this.cloudFormVisible = true
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		S3KeyAnalyse(item) {
			this.s3form.uuid = item.uuid
			this.s3form.file_time = item.file_time
            this.s3form.appid = item.schema
			this.GetS3key()
		},
		closeCloud(item) {
			this.closeCloudId = item.id
			// this.closeCloudUid=item.uid
			// this.closeCloudUuid=item.uuid
			this.isShowCloseCloudDialog = true
		},
		closelCloudConfirm() {
			let data = {
				exit_order: this.isReturnMoney,
				id: this.closeCloudId
			}
			this.api.CloseCloud(data).then(res => {
				if (res.data.code == 200) {
					this.$message({
						"type": "success",
						"message": "云存储关闭成功！"
					});
					this.isShowCloseCloudDialog = false
					this.cloudFormVisible = false
					// this.GetCloudInfo({uid:this.closeCloudUid, uuid:this.closeCloudUuid})
				} else {
					this.$message({
						"type": "error",
						"message": res.data.msg
					});
				}
			}).catch(err => {
				console.log('请求失败：', err);
			})
		},
		cancelCloudRenew(item) {
			this.$confirm('确定取消当前设备订阅的云存储？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					uuid: item.uuid,
					uid: item.uid
				}
				this.api.CancelCloudRenew(data).then(res => {
					if (res.data.code == 200) {
						this.$message({
							"type": "success",
							"message": "云存储套餐订阅取消成功！"
						});
					} else {
						this.$message({
							"type": "error",
							"message": res.data.msg
						});
					}
				}).catch(err => {
					console.log('请求失败：', err);
				})
			}).catch(() => {});
		},
		GetS3key() {
			this.s3KeyList = []
			this.form = {}
			let time = this.s3form.date ? util.dateSingle(this.s3form.date) : ''
			this.api.FileLogListAnalyse({
				rows: 10,
				prefix: 'media' + this.s3form.file_time + '/' + this.s3form.appid + '/' + this.s3form.uuid + '_' + this.s3form.uid + '/' + time,
				// region: this.search.region
			}).then(res => {
				if (res.data.code == 200) {
					this.s3FormVisible = true
					this.formVisibl = true
					this.analyseform.appid_equal = res.data.data.appid_equal
					this.analyseform.file_brand = res.data.data.file_brand
					this.analyseform.brand_equal = res.data.data.brand_equal
					this.analyseform.version = res.data.data.version
					this.analyseform.region = res.data.data.region
					this.analyseform.file_key = res.data.data.file_key
					this.analyseform.key_appid_right = res.data.data.key_appid_right
					this.analyseform.key_brand_right = res.data.data.key_brand_right
					this.s3KeyList = res.data.data.data
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		CreateConfirm() {
			this.api.OnlineCreateCloud(this.creatcloudform, {
				region: this.search.region
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('套餐开通成功!')
					this.packFormVisible = false
					return
				}
				this.$message.error('套餐开通失败' + res.data.msg)
			})
		},
		Validate: function(call) {
			this.$refs['creatcloudform'].validate(res => {
				if (res && call) {
					call()
				}
			})
		},

		// ---------------------分割线-----------------------
		ChangeStatus(item) {
			return util.handleValueDisplay(logEventStatus, item)
		},
		TuyaLog(item) {
			this.tuyaLogVisible = true;
			this.tuyalogform.device_id = item.id;
			this.GetTuyaLogList(item)
		},
		GetTuyaLogList(item) {
			this.api.UserTuyaLog({
				device_id: this.tuyalogform.device_id,
				tuya_region: this.region,
				type: this.tuyalogform.type,
				codes: this.tuyalogform.codes,
				start_row_key: this.tuyalogform.start_row_key,
				start_time: this.datevalue ? Date.parse(this.datevalue[0]) / 1000 : null,
				end_time: this.datevalue ? Date.parse(this.datevalue[1]) / 1000 : null,
				size: this.tuyalogform.size,
			}).then(res => {
				this.tuyalogList = res.data.data.logs
			})
		},
		TuyaInfo(item) {
			this.tuyaFormVisible = true
			this.tuyaform = item
			this.tuyaform.create_time = this.changeTime(this.tuyaform.create_time)
			this.tuyaform.active_time = this.changeTime(this.tuyaform.active_time)
			this.tuyaform.update_time = this.changeTime(this.tuyaform.update_time)
		},
		NextPageLog() {
			this.logform.lastKey = this.logList.length ? this.logList[this.logList.length - 1].key : ''
			this.GetLog()
		},
		AppLog(item) {
			this.logform.prefix = 'applog/' + item.schema + '/' + item.uid + '/'
			console.log(this.logform.prefix)
			this.logform.appid = item.schema
			this.logFormVisible = true
		},
		LogValidate(call) {
			this.$refs['logform'].validate(res => {
				if (res && call) {
					call()
				}
			})
		},
		GetLog() {
			this.api.FileLogList({
				brand: this.logform.brand,
				appid: this.logform.appid,
				lastKey: this.logform.lastKey,
				rows: this.logform.rows,
				prefix: this.logform.prefix + util.dateSingle(this.logform.date),
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.logList = res.data.data
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		DownLog(item) {
			this.api.FileLogDown({
				brand: '',
				appid: this.logform.appid,
				is_cdn: '',
				key: item.key,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					const link = document.createElement('a');
					link.setAttribute('href', res.data.data.get_url)
					link.click()
					return
				}
				this.$message.error('下载失败' + res.data.msg)
			})
		},
		Getapp() {
			this.api.ClientList({
				page: 1,
				limit: 100,
			}).then(res => {
				if (res.data.code == 200) {
					this.brandlist = res.data.data.data
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		GetTuyaList() {
			this.api.UserTuyaList({
				uid: this.uid,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.tuyalist = res.data.data
					this.GetBindDevice()
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		changePushType(item) {
			return util.handleValueDisplay(this.pushType, item)
		},
		Looktoken(item) {
			this.tokenform.token = item
			this.tokenFormVisible = true
		},
		changeStatus(type, item, status) {
			let paramType = ''
			let paramVal = ''
			if (type == 'auth_tow' && status) {
				paramVal = null
			} else if (status) {
				paramVal = 0
			} else {
				paramVal = 1
			}
			if (type == 'active_push') {
				paramType = 'ActivePush'
			}
			if (type == 'auth_tow') {
				paramType = 'FactorAuth'
			}
			if (type == 'is_debug') {
				paramType = 'Debug'
			}
			if (type == 'is_developer') {
				paramType = 'Developer'
			}
			if (type == 'is_test') {
				paramType = 'Test'
			}
			this.api.UserAppStatus({
				change_type: paramType,
				uid: item.uid,
				value: paramVal,
				schema: item.schema,
			}, {
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('状态修改成功')
					this.GetUserAppList()
					return
				}
				this.$message.error('状态修改失败' + res.data.msg)
			})
		},
		statusValidate(call) {
			this.$refs['statusform'].validate(res => {
				if (res && call) {
					call()
				}
			})
		},
		statusconfirm() {
			this.api.UserAppStatus({
				change_type: this.statusform.change_type,
				uid: this.statusform.uid,
				value: this.statusform.change_type == 'FactorAuth' ? null : this.statusform.value,
				schema: this.statusform.schema,
			}, {
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.$message.success('状态修改成功')
					this.statusFormVisible = false
					this.GetUserAppList()
					return
				}
				this.$message.error('状态修改失败' + res.data.msg)
			})
		},
		prePage() {
			if (this.search.page - 1 !== 0) {
				this.search.page = this.search.page - 1
				this.GetUserAppList()
			} else {
				this.predisabled = true
			}
		},
		nextPage() {
			this.search.page = this.search.page + 1
			this.GetUserAppList('next')
		},
		GetUserInfo() {
			this.api.UserManageDetail({
				uid: this.uid,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.form = res.data.data.migrage_user
					this.schema = res.data.data.migrage_user.schema
					this.userform = res.data.data.user
					this.form.create_time = util.dateFormat(res.data.data.migrage_user.create_time)
					this.userform.register_time = util.dateFormat(res.data.data.user.register_time)
					this.userform.delete_time = util.dateFormat(res.data.data.user.delete_time)
					this.userform.update_time = util.dateFormat(res.data.data.user.update_time)
					this.userform.register_ip = util.toIP(res.data.data.user.register_ip)
					this.GetLoginList()
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		GetLoginList() {
			this.api.UserLoginList({
				page: 1,
				limit: 100,
				uid: this.uid,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.loginlist = res.data.data
					this.GetTrustDevList()
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		GetTrustDevList() {
			this.api.UserTrustDevList({
				page: 1,
				limit: 100,
				uid: this.uid,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					this.trustDevlist = res.data.data
					this.GetUserAppList()
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		GetUserAppList(item) {
			this.api.UserAppList({
				page: this.search.page,
				limit: this.search.limit,
				uid: this.uid,
				region: this.region
			}).then(res => {
				if (res.data.code == 200) {
					if (res.data.data) {
						this.predisabled = false
						this.nextdisabled = false
						this.list = res.data.data
						if (this.search.page == 1) {
							this.predisabled = true
						}
						if (this.list.length < this.search.limit) {
							this.nextdisabled = true
						}
					} else if (item == 'next') {
						this.nextdisabled = true
					}
					this.GetTuyaList()
					return
				} else if (res.data.msg == '系统错误no data') {
					this.predisabled = true
					this.nextdisabled = true
					return
				}
				this.$message.error('获取失败' + res.data.msg)
			})
		},
		changeTime(item) {
			return util.dateFormat(item)
		},
		goback() {
			if (this.$route.params.uid.split('&')[2] == 'online') {
				this.$router.push('/iot/onlineinfo/' + this.$route.params.uid.split('&')[3])
			} else if (this.$route.params.uid.split('&')[2] == 'feedback') {
				this.$router.push('/iot/feedbackinfo/' + this.$route.params.uid.split('&')[3])
			} else if (this.$route.params.uid.split('&')[2] == 'tuya') {
				this.$router.push('/iot/tuya/')
			} else {
				// this.$router.push('/iot/user')
                this.$router.go(-1)
			}
		},
	},
	filters: {},
	mounted() {
		this.uid = this.$route.params.uid.split('&')[0]
		this.region = this.$route.params.uid.split('&')[1]
		this.params = this.$route.params.uid
		this.GetUserInfo()
	},
	created() {
		this.Getapp()
	}
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.page {
	float: right;
	margin-top: 10px
}

.close_cloud {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.close_cloud_select {
	width: 100px;
	height: 30px;
	border-radius: 5px;
	margin-bottom: 20px;
}
</style>
<style>
.el-dialog {
    padding-bottom: 10px;
}
</style>
